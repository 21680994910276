import {IEntityAction, ActionMeta} from 'app/blocks/store/actions/entity-actions';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';

export interface IGetCompanyEntityOnLoadWithoutId extends IEntityAction {
    settingResourceName?: string;
}

export class GetCompanyEntityOnLoadWithoutId implements IGetCompanyEntityOnLoadWithoutId {
    static readonly type = '[Generic] Get Entity With Id (like company)';
    constructor(
        public resource: AbstractEntityResource<any>,
        public settingResourceName,
        public meta?: ActionMeta
    ) {}
}
